import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";

interface Props {
    children?: any
}

const VerticalContainer = ({children}: Props) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Container theme={theme}>
            {children}
        </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Container = Style.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: ${props => props.theme.sectionWidth};

    @media screen and (max-width: 420px) {
        width: 100%;
    }
`;

export default VerticalContainer;
