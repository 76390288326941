import React from 'react'
import Style from "styled-components"
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType, LinkType } from '../../utils/types/types';
import Label from '../Label';

interface Props {
    type: LinkType
    url: string
}

const Button = ({ type, url }: Props) => {
    const theme = React.useContext(ThemeContext);

    const getIconPath = (): string => {
        switch (type) {
            case LinkType.website:
                return './assets/icons/website.png';
        }
    }

    const getText = (): string => {
        switch (type) {
            case LinkType.website:
                return 'see_website.button';
        }
    }

    return (
        <Container theme={theme} href={url} target="_blank">
            <Icon alt='icon' src={getIconPath()} />
            <Label text={getText()} margin="0px 0px 0px 10px" type={FontType.body} fontStyle={FontStyle.button} color={ColorStyle.button} mode={ColorMode.light} align='left'></Label>
        </Container>
    )
}

interface ContentProps {
    theme: object;
}

const Icon = Style.img<ContentProps>`
    flex: none;
    flex-grow: 0;
    width: ${props => props.theme.spacing.buttonText};
`;

const Container = Style.a<ContentProps>`
    flex: none;
    flex-grow: 0;
    margin: ${props => props.theme.spacing.tiny};
    display: flex;
    align-items: center;
    flex-direction: row;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 48px;
    
    background: ${props => props.theme.color.foreground};
    border-radius: ${props => props.theme.borderRadius.regular};
    transition: 0.3s;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
    &:hover {
        transform: scale(1.02);
        ${props => props.theme.shadow.focused};
    }
    &:active {
        transform: scale(0.96);
        ${props => props.theme.shadow.focused};
    }
`;

export default Button;
