export enum ColorMode {
    light = 'light',
    dark = 'dark',
}

export enum ColorStyle {
    title = 'title',
    body = 'body',
    info = 'info',
    button = 'button',
}

export enum FontType {
    title = 'title',
    body = 'body',
}

export enum FontStyle {
    header = 'header',
    button = 'button',
    body = 'body',
    info = 'info',
}

export enum LinkType {
    website
}