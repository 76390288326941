import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";

interface ProfilePictureProps {
    iconPath: string;
}

const ProfilePicture = ({ iconPath }: ProfilePictureProps) => {
    const theme = React.useContext(ThemeContext);
    return (
        <Container theme={theme}>
            <Icon alt="picture" src={iconPath} theme={theme}/>
        </Container>
    )
}

const Icon = Style.img<{
    theme: object;
}>`
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
    width: inherit
`;

const Container = Style.div<{
    theme: object;
}>`
    background: ${props => props.theme.color.foreground};
    width: ${props => props.theme.profilePictureDiameter};
    height: ${props => props.theme.profilePictureDiameter};
    overflow: hidden;
    border-radius: ${props => props.theme.borderRadius.rounded};

    /* Inside Auto Layout */

    flex: none;
    flex-grow: 0;
    margin: ${props => props.theme.spacing.medium} 0px;
    
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default ProfilePicture;
